.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 17rem;
  /* height: 300px; */
}
.home-page .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px !important;
  height: 420px !important;
}
/* Adjust width at approximately 125% scale */
@media only screen and (min-resolution: 120dpi),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx) {
  .swiper-slide {
    width: 14rem;
  }
  .home-page .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 17rem !important;
    /* height: 350px !important; */
  }
}
@media only screen and (min-resolution: 150dpi),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 1.5dppx) {
  .swiper-slide {
    width: 11rem;
  }
}

.home-page .swiper-wrapper {
  margin-top: 10vh;
}
/* .home-page .swiper-slide:not(.active-slide) {
  opacity: 0.8; /* Smaller size for non-active slides */
/*} */
.home-page .swiper.swiper-coverflow {
  z-index: -1;
}
.home-page .swiper-slide.active-slide {
  transform: scale(1.2) !important;
  transition-delay: 0.3s;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: none;
}
.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}
.swiper-slide img {
  display: block;
  width: 100%;
}
